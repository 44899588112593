import { useEffect, useRef } from "react";
import { S_GOOGLE_PAY_UPDATE_STAUS } from "../GlobalObserver";
import { REQ_AP_ONMERCHANT_VALIDATION, REQ_AP_PAY } from "../managers/AP";

const ApplePaySession=(window as any).ApplePaySession;
const applePaySdkJs="https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js"
const scriptScreated=false;
const createScript=()=>{
    if(scriptScreated)
        return;
    const s = document.createElement("script") as HTMLScriptElement;
    s.async=true;
    s.crossOrigin="anonymous";
    s.src=applePaySdkJs
    document.head.appendChild(s)

    // Attach style
    const style = document.createElement("style") as HTMLStyleElement;
    style.innerHTML = `
        apple-pay-button {
            --apple-pay-button-width: 240px;
            --apple-pay-button-height: 40px;
            --apple-pay-button-border-radius: 4px;
            --apple-pay-button-padding: 0px 0px;
            --apple-pay-button-box-sizing: border-box;
        }
    `
    document.head.appendChild(style);
}

if(ApplePaySession)
    createScript()

const merchantIdentifier = 'merchant.lv.newtech.apgp';
const paymentRequestData = {
    supportedMethods: "https://apple.com/apple-pay",
    data: {
        version: 3,
        merchantIdentifier: merchantIdentifier,
        merchantCapabilities: ["supports3DS"],
        supportedNetworks: ["masterCard"],
        countryCode: "US"
    }
}



export const APButton=({payinfo}:{payinfo:PayParams})=>{
    const ref = useRef<HTMLDivElement|null>(null)

    // Apple pay click!
    const onApplePayClick=async ()=>{
        S_GOOGLE_PAY_UPDATE_STAUS.invoke("ap click")
        const request:any = new PaymentRequest([paymentRequestData],{
            total: {
                label: "ORDER ID: "+payinfo.merchantOrderID,
                amount: {
                    value: payinfo.amount+"",
                    currency: payinfo.currency
                }
            }
        }) // EOF REQUEST

        request.onmerchantvalidation=async (event:any)=>{
            console.log("Try to validate merchant: "+event.validationURL)
            try{
                const response = await REQ_AP_ONMERCHANT_VALIDATION.request({
                    ssid:payinfo.ssid,
                    validationURL:event.validationURL
                })
                console.log("AP response: ",response)
                event.complete(response);
            }catch(e:any){
                let msg=e.message ?? "Apple pay error"
                if("t0" in e){
                    const abortError=e['t0'];
                    msg = abortError.message;
                }
                S_GOOGLE_PAY_UPDATE_STAUS.invoke(msg);
                console.error(1,msg);
            }
            //request.completeMerchantValidation(responseData)
        }

        request.onpaymentmethodchange = (event:any) =>{
            if (event.methodDetails.type !== undefined) {
                // Define PaymentDetailsUpdate based on the selected payment method.
                // No updates or errors needed, pass an object with the same total.
                const paymentDetailsUpdate = {
                    total: {
                        label: "ORDER ID: "+payinfo.merchantOrderID,
                        amount: {
                            value: payinfo.amount,
                            currency: payinfo.currency
                        }
                }}
                event.updateWith(paymentDetailsUpdate);
            }
        }// EOF onpaymentmethodchange

        let response; 
        try{
            response = await request.show();
            await response.complete("success");
        }catch(e:any){
            let msg=e.message ?? "Apple pay error"
            if("t0" in e){
                const abortError=e['t0'];
                msg = abortError.message;
            }
            S_GOOGLE_PAY_UPDATE_STAUS.invoke(msg);
            console.error(msg);
        }
        
        // send payment request
        if(!response){
            console.error("No response")
            return;
        }
        await REQ_AP_PAY.request({
            ssid:payinfo.ssid,
            token:response.details.token
        })
    }

    useEffect(()=>{
        if(!ApplePaySession){
            console.error("No ApplePaySession, can't initialize button")
            return;
        }
        console.log("Trying to setup button")
        let apBtn:HTMLElement | null=null;
        const promise = ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);
        promise.then(function (canMakePayments:boolean) {
            if (canMakePayments){
                S_GOOGLE_PAY_UPDATE_STAUS.invoke("ap has card")
            }else{
                S_GOOGLE_PAY_UPDATE_STAUS.invoke("ap no card")
            }

            if(ref.current){
                apBtn = document.createElement("apple-pay-button") as HTMLElement;
                apBtn.onclick=onApplePayClick;
                apBtn.setAttribute("buttonstyle","black");
                apBtn.setAttribute("type","pay");
                apBtn.setAttribute("locale","en-US");
                ref.current.innerHTML="";
                ref.current.appendChild(apBtn);
            }

        }).catch((e:any)=>{
            console.error("Can't create Apple Pay Button")
            S_GOOGLE_PAY_UPDATE_STAUS.invoke("can't create ap")
        })

        return ()=>{
            if(apBtn){
                apBtn.remove()
                apBtn.onclick=null;
            }
        }
    },[ref])

    return <div ref={ref} style={{textAlign:"center"}}>Initializing...</div>
}
