import { createBrowserRouter,createRoutesFromElements,Outlet,Route,RouterProvider } from 'react-router-dom';
import { Content } from './Content';
import { ErrorPage } from './ErrorPage';
const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            path='/'
            element={<Outlet/>}
            errorElement={<ErrorPage />}
        >
             <Route errorElement={<div>Inner Error</div>}>
                <Route
                    path='session/:ssid'
                    element={<Content />}
                />
                <Route
                    path='success'
                    element={<Content/>}
                />
                <Route
                    path='fail'
                    element={<Content/>}
                />
             </Route>
        </Route>
    )
)

export const AppRouter = ()=>{
    return <RouterProvider router={router} />
}