import Config from "../Config";
import { S_BUSY, S_SESSION_INFO_ERROR, S_SESSION_PAY_COMPLETE } from "../GlobalObserver";
import { SyncSignal } from "../utils/Signal";

export const REQ_AP_ONMERCHANT_VALIDATION = new SyncSignal<{ssid:string,validationURL:string},any>();
export const REQ_AP_PAY = new SyncSignal<{ssid:string,token:any},any>();
export class AP{
    constructor(){
        REQ_AP_ONMERCHANT_VALIDATION.listener=async (data,cb)=>{
            //S_BUSY.invoke(true)
            try{
                const response = await fetch(Config.scheme.URL_API+"/apple/session/"+data.ssid,{
                    method:"POST",
                    headers:{
                        "content-type":"application/json",
                    },
                    body:JSON.stringify({
                        validationURL:data.validationURL
                    })
                })
                const responseData = await response.json();
                if(responseData.error){
                    S_BUSY.invoke(false)
                    S_SESSION_INFO_ERROR.invoke(responseData);
                    return;
                }
               
                S_BUSY.invoke(false)
                cb(responseData.data);
            
            }catch(e){
                console.error("can't procees response from server")
            }
            S_BUSY.invoke(false)
        }


        // PROCESS WITH PAYMENT
        REQ_AP_PAY.listener=async (data,cb)=>{
            S_BUSY.invoke(true)
            try{
                const response = await fetch(Config.scheme.URL_API+"/apple/pay/"+data.ssid,{
                    method:"POST",
                    headers:{
                        "content-type":"application/json",
                    },
                    body:JSON.stringify(data.token)
                })

                S_BUSY.invoke(false)

                const responseData = await response.json();

                if(responseData.error){
                    S_SESSION_INFO_ERROR.invoke(responseData);
                    return;
                }

                S_SESSION_PAY_COMPLETE.invoke(responseData)

            }catch(e){
                console.error("network error");
            }
            S_BUSY.invoke(false)
        }
    }
}