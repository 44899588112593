import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle<any>`
    body{
        background-color:#F1F1F1;
        font-family: 'Inter', sans-serif;
        color:#111111;
        padding: 0;
        margin: 0;
        font-size: 12pt;
    }

    #reactroot{
        display: flex;
        flex-direction:column;
        height: 100vh;
        width: 100vw;
    }

    
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        background-color:rgba(0,0,0,.2);
        border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb {
        background-color:rgba(0,0,0,.3);
        border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color:rgba(0,0,0,1);
    }
`
