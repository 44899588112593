import styled from "styled-components"
import logo from "../assets/logo.svg"

const LogoBoxDiv = styled.div`
    background-color: #23131c;
    width: 100%;
    padding: 10pt 20pt;
    box-sizing: border-box;
    display: flex;
    color:rgba(255,255,255,.5);
    font-size:11pt;
    align-items: center;
    gap:20pt;
    >div:last-child{
        flex-grow: 1;
        margin-top: 5px;
        text-align:right;
    }
`

let w="100px"
let h="60px"
const LogoDiv = styled.div`
    background-image:url(${logo});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    min-width: ${w};
    min-height: ${h};
    width: ${w};
    height: ${h};

`
export const Logo = ()=>{
    return <LogoBoxDiv><LogoDiv/><div>Your payment partner</div></LogoBoxDiv>
}