import styled from "styled-components"


const InfoDiv=styled.div`
    display: flex;
    flex-direction: column;
    gap:20pt;
    align-items: center;
`

const PriceDiv = styled.div`
    display: flex;
    gap:20px;
    font-size: 15pt;
    align-items: center;
    &>div:first-child{
        font-weight: bold;
        flex-grow: 1;
    }
`

const DescriptionDiv=styled.div`

    display: flex;
    flex-direction: column;
    gap:3pt;

    &>div:first-child{
        font-size: 11pt;
        opacity: .7;
    }
   
    &>div:nth-child(2){
        font-size: 15pt;
        ::before{
            content: "#";
        }
    }
    
    
`

const PriceAmountDiv = styled.div`
    display: flex;
    gap:10px;
    font-size: 15pt;
    &>div[data-sub]::after{
        content: attr(data-sub);
        opacity: .5;
        font-weight: normal;
    }
    &>div[data-sub]{
        font-weight: bold;
    }
    background-color: rgba(0,0,0,.05);
    border:1px solid rgba(0,0,0,.09);
    padding: 10px;
    border-radius: 4px;
    
`

interface LogoDivProps{
    src:string
}
const LogoDiv = styled.div<LogoDivProps>`
    background-image: url(${(props)=>props.src});
    width: 60%;
    padding-top:60%;
    background-size: 80% auto;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(0,0,0,.05);
    border:1px solid rgba(0,0,0,.1);
    border-radius:50%;
    box-shadow: 0px 4px 14px rgba(0,0,0,.1);
    background-color: #FFFFFF;

`

const Logo = (params:{src:string})=>{
    return <LogoDiv src={params.src}></LogoDiv>
}

interface PriceProps{
    amout:string,
    currency:string
}
const Price = ({amout,currency}:PriceProps)=>{
    let sub = ".00"
    const tmp = amout.split(".");
    if(tmp.length>1){
        sub="."
        sub+=tmp[1];
        if(tmp[1].length===1)
            sub+="0"
        amout=tmp[0]
    }

    return <PriceDiv>
        <div>Total: </div>
        <PriceAmountDiv>
            <div>{currency}</div>
            <div data-sub={sub}>{amout}</div>
        </PriceAmountDiv>
    </PriceDiv>
}


interface DescriptionProps{
    title:string,
    subtitle:string
}
const Description = ({title,subtitle}:DescriptionProps)=>{
    return <DescriptionDiv>
        <div>{title}</div>
        <div>{subtitle}</div>
    </DescriptionDiv>
}

export const Info = (params:PayParams) =>{
   return <InfoDiv> 
        {params.logo && <Logo src={params.logo} />}
        {params.merchantOrderID && <Description title={"ORDER ID:"} subtitle={params.merchantOrderID} />}
        <Price amout={params.amount+""} currency={params.currency} />
    </InfoDiv>
}