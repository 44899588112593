interface Scheme{
    URL_API:String
}
class Config{

    static live:Scheme = {
        URL_API:"https://apgp.newtech.lv/api"
    }

    static local:Scheme ={
        URL_API:"http://localhost:8091/api"
    }

    static scheme:Scheme = Config.live
    
}
export default Config;