import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './views/App';
import { TokenHandler } from './managers/TokenHandler';
import axios from 'axios';
import { AP } from './managers/AP';

//const gp = new GooglePay();
// Initalize managers
new TokenHandler();
new AP();

const el=document.createElement("div");
el.id="reactroot"
document.body.appendChild(el)
const root = ReactDOM.createRoot(el);

async function test(live?:boolean,amount?:number,b?:any){

    let url = "https://apgp.newtech.lv"
    let redirectUrl="https://apgp.newtech.lv"

    if(!live){
        url = "http://localhost:8091"    
        redirectUrl = "http://localhost:3000"
    }
    
    let body={
            "key": "Impayacom_apgp_passthrough",
            "merchant_order_id": "2023080813938601",
            "amount": amount ?? 100,
            "type": "pay",
            "lifetime": 10000,
            "credential": {
              "terminal_password": "NoPa$$W0rd!"
            },
            "custom_params_raw": "successUrl=https://www.impaya.online/new/payment_system/?system=em_impayaru_gp&act=return&trans=2000e2a30b3f5115529a8f18369464bc,2023080813938601&success;failUrl=https://www.impaya.online/new/payment_system/?system=em_impayaru_gp&act=return&trans=2000e2a30b3f5115529a8f18369464bc,2023080813938601&fail;Email="
          
        }
        
    if(b)
        body=b;

    const u = url+"/api/session"

    console.log("SESSION REQ: "+u+"\n"+JSON.stringify(body,null,"\t\t"));


    

    try{
    const resp = await axios.post(u,body);
    if(resp && resp.data){
        if(typeof resp.data === "object" )
            console.log("SESSION RESP:",JSON.stringify(resp.data,null,"\t\t"))
        else
            console.log("SESSION RESP:",resp.data);

        const redirect = redirectUrl + "/session/"+resp.data.SessionGUID  
        console.log("REDIRECT: "+redirect);
        setTimeout(()=>{
            document.location.href = redirect;
        },1000*2)
        
    }
    }catch(e){
        console.error("AXIOS ERROR",e);
    }


}

(window as any)["test"] = test;
console.log("ver.: 1.1.7")


// DETECT APPLE PAY!
export const ApplePaySession=(window as any).ApplePaySession;
console.log(`Apple Pay ${ApplePaySession?"":"not"} available`)


root.render(<React.StrictMode>
    <App />
</React.StrictMode>);