import { ApplePaySession } from "..";
import Config from "../Config";
import { S_BUSY, S_GOOGLE_PAY_COMPLETE, S_GOOGLE_PAY_UPDATE_STAUS, S_REQUEST_SESSION_INFO, S_SESSION_INFO_ERROR, S_SESSION_INFO_LOADED, S_SESSION_PAY_COMPLETE, S_SESSION_PAY_ERROR } from "../GlobalObserver";
import axios, { AxiosError } from "axios"
export class TokenHandler{
    private static instance:boolean;
    
    private currentSession:PayParams|null = null;
    private retries=5;
    constructor(){

        if(TokenHandler.instance){
            console.error("TokenHandler already got an instance");
            return;
        }
        TokenHandler.instance = true;

        window.addEventListener('beforeunload', function (e) {
            S_GOOGLE_PAY_UPDATE_STAUS.invoke("winclose")
        });

        S_GOOGLE_PAY_UPDATE_STAUS.add(async status=>{
            if(!this.currentSession )
                return;
            const resp = await axios.post(Config.scheme.URL_API+"/session/status/"+this.currentSession.ssid,{
                status:status
            })    
            if(resp.data.error)
                console.error(resp.data.error)
        })

        S_REQUEST_SESSION_INFO.add(async ssid=>{
            this.currentSession=null;
            S_BUSY.invoke(true)
            try{
                const resp = await axios.post(Config.scheme.URL_API+"/session/check/"+ssid)    
                if(resp.data.error){
                    console.error(resp.data.error)
                    S_SESSION_INFO_ERROR.invoke(resp.data);
                    S_BUSY.invoke(false)
                    return;
                }

                if(!resp.data.data){
                    S_BUSY.invoke(false)
                    return;
                }
                
                this.currentSession =resp.data.data as PayParams;
                this.currentSession.isApAvailable=!!ApplePaySession



                S_SESSION_INFO_LOADED.invoke(this.currentSession!);

            }catch(e){
                console.error("Axios load error")
            }
            S_BUSY.invoke(false)
        })

        S_GOOGLE_PAY_COMPLETE.add(async pdata=>{
            S_BUSY.invoke(true)
            try{
                const resp = await axios.post(Config.scheme.URL_API+"/gp",{token:pdata,ssid:this.currentSession?.ssid ?? ""})
                if(resp.data.error){
                    console.error(resp.data.error)
                    if(typeof resp.data.data ==="object" && resp.data.data && resp.data.data.status && resp.data.data.status === -10){
                        if(this.retries>0){
                            this.retries--;
                            setTimeout(()=>{
                                S_GOOGLE_PAY_COMPLETE.invoke(pdata);
                            },1000)
                            return;
                        }
                    }
                    S_SESSION_PAY_ERROR.invoke(resp.data);
                    S_BUSY.invoke(false)
                    return;
                }
                S_SESSION_PAY_COMPLETE.invoke(resp.data)
                console.log(resp.data);
                
            }catch(err){
                const e = err as AxiosError;
                if(this.retries>0){
                    this.retries--;
                    setTimeout(()=>{
                        S_GOOGLE_PAY_COMPLETE.invoke(pdata);
                    },1000)
                    return;
                }
                S_SESSION_PAY_ERROR.invoke({data:null,error:{code:e.response?.status ?? 0,message:e.message ?? "UNKNOWN"}})
            }
            S_BUSY.invoke(false)
            
        })
    }
}