import GooglePayButton from "@google-pay/button-react"
import { S_GOOGLE_PAY_COMPLETE, S_GOOGLE_PAY_UPDATE_STAUS } from "../GlobalObserver"
import Config from "../Config"

export const GPButton = ({payinfo}:{payinfo:PayParams}) =>{
    return <GooglePayButton

        onCancel={()=>{S_GOOGLE_PAY_UPDATE_STAUS.invoke("cancel")}}
        onError={(err)=>{S_GOOGLE_PAY_UPDATE_STAUS.invoke((err as any)?.statusCode ?? err)}}
        onClick={()=>{S_GOOGLE_PAY_UPDATE_STAUS.invoke("btnclick")}}

        environment={(Config.scheme === Config.local)?"TEST":"PRODUCTION"}
        buttonColor="default"
        buttonType="pay"
        buttonLocale={payinfo.locale ?? "en"}

        paymentRequest={{
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
                {
                    type: 'CARD',
                    parameters: {
                        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                        allowedCardNetworks: ['MASTERCARD', 'VISA'],
                    },
                    tokenizationSpecification: {
                        type: 'PAYMENT_GATEWAY',
                        parameters: {
                            gateway: 'newtechmobile',
                            gatewayMerchantId: 'newtechmobile',
                        },
                    },
                },
            ],
            merchantInfo: {
                merchantId: 'BCR2DN4TYSWLZMR3',
                merchantName: 'NewTech Mobile SIA',
            },
            transactionInfo: {
                totalPriceStatus: 'FINAL',
                totalPriceLabel: 'Total',
                totalPrice: payinfo.amount+"",
                currencyCode: payinfo.currency,
                countryCode: payinfo.country,
            },
        }}
        onLoadPaymentData={paymentRequest => {
            S_GOOGLE_PAY_COMPLETE.invoke(paymentRequest);
        }}
    />
}