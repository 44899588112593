import Signal from "./utils/Signal";



export const S_GOOGLE_PAY_READY:Signal<void> = new Signal()
export const S_GOOGLE_PAY_ERROR:Signal<void> = new Signal()
export const S_GOOGLE_PAY_REQUEST:Signal<void> = new Signal();
export const S_GOOGLE_PAY_COMPLETE:Signal<google.payments.api.PaymentData>=new Signal()
export const S_REQUEST_SESSION_INFO:Signal<string>=new Signal() // ssid
export const S_SESSION_INFO_LOADED:Signal<PayParams>=new Signal() // ssid
export const S_SESSION_INFO_ERROR:Signal<HTTPResponseVO>=new Signal() // ssid
export const S_SESSION_PAY_COMPLETE:Signal<HTTPResponseVO>=new Signal() // ssid
export const S_SESSION_PAY_ERROR:Signal<HTTPResponseVO>=new Signal() // ssid
export const S_GOOGLE_PAY_UPDATE_STAUS:Signal<string>=new Signal();
export const S_BUSY:Signal<boolean>=new Signal();
export const S_RETRY_PAY:Signal<void>=new Signal();