import { ReactElement, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { Status } from "./Status";
import { Info } from "./Info";
import { S_BUSY, S_GOOGLE_PAY_UPDATE_STAUS, S_REQUEST_SESSION_INFO, S_RETRY_PAY, S_SESSION_INFO_ERROR, S_SESSION_INFO_LOADED, S_SESSION_PAY_COMPLETE, S_SESSION_PAY_ERROR } from "../GlobalObserver";
import { SignalHandler } from "../utils/Signal";
import { GPButton } from "./GPButton";
import { APButton } from "./APButton";



const ContentDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap:30pt;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
`

const APWarningDiv = styled.div`
    background-color: rgba(0,0,0,.05);
    border:1px solid rgba(0,0,0,.1);
    padding:10pt;
    text-align: center;
    border-radius: 2px;
    color:rgba(0,0,0,.6);
    max-width: 70vw;
`

function useQuery() {
    const { search } = useLocation();
    console.log(search)
    return useMemo(() => new URLSearchParams(search), [search]);
}



const Button = styled.div`
    font-size: 10pt;
    padding: 8pt 10pt;
    border:1px solid rgba(0,0,0,.1);
    border-radius: 4px;
    box-shadow: 0px 2px 2px rgba(0,0,0,.1);
    background-color: #FFFFFF;
    cursor: pointer;
    
`
const ReturnBlockDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap:10pt;
    align-items: center;
    >div:last-child{
        font-size: 11pt;
        text-align: center;
        line-height: 15pt;
        color:rgba(0,0,0,.6);
    }
`

export const Content = (p:any) =>{

    const [payinfo,setPayInfo] = useState<PayParams|null>(null);
    const [response,setResponse] = useState<HTTPResponseVO|null>(null)
    const [busy,setBusy] = useState<boolean>(false)

    const {ssid,success,fail} = useParams();
    const location = useLocation();
    
    useEffect(()=>{
        
        if(location.pathname === "/success"){
            setResponse({
                error:null,
                data:null
            });
            return;
        }

        if(location.pathname === "/fail"){
            setResponse({
                error:{code:-500,message:"Failed"},
                data:null
            });
            return;
        }

        if(!ssid){
            console.error("NO SID!")
            return;
        }
        
        const sh:SignalHandler = new SignalHandler();
        sh.add(S_RETRY_PAY,()=>{
            setResponse(null)
        })
        sh.add(S_SESSION_INFO_LOADED,(resp)=>{
            setPayInfo(resp)
        });
        sh.add(S_SESSION_INFO_ERROR,data=>{
            setResponse(data);
        })

        sh.add(S_SESSION_PAY_ERROR,data=>{
            setResponse(data);
        })

        sh.add(S_SESSION_PAY_COMPLETE,data=>{
            setResponse(data)
        })

        sh.add(S_BUSY,b=>{
            setBusy(b)
        })

        S_REQUEST_SESSION_INFO.invoke(ssid);

        return ()=>{sh.clear()}
        
    },[ssid])

    if(response){
        let status = "complete";
        let message = null;
        if(response.error){
            if(response.error.code === 15){
                status="card"
                message="Wrong card type, only Mastercard accepted"
            }else{
                status="error"
                message =`[${response.error.code}] - ${response.error.message}`;
                if(response.error.code === -500){
                    status = "failed"
                    message=null;
                }
            }
        }

        const navigateBackToMerchant = ()=>{
            if(!response.data?.redirect)
                return;
            window.location.href = response.data.redirect
        }

        if(response.data?.redirect){
            setTimeout(()=>{
                navigateBackToMerchant();
            },1000*3);
        }

        return <ContentDiv>
                <Status status={status} message={message}/>
                {response.data?.redirect &&
                    <ReturnBlockDiv>
                        <Button onClick={navigateBackToMerchant}>BACK TO MERCHANT</Button>
                        <div>You will automatically return to the merchant<br></br> in a few seconds.</div>
                    </ReturnBlockDiv>
                }
        </ContentDiv>
    }

    if(busy){
        return <ContentDiv>
            <Status status={"processing"} message={"Please wait..."}/>
        </ContentDiv>
    }

    //setup button!
    let buttons:ReactElement[]=[];
    let apWarning=null;

    if(!payinfo)
        return <ContentDiv></ContentDiv>
    
    if(payinfo.psys==="ap" && !payinfo.isApAvailable){
        S_GOOGLE_PAY_UPDATE_STAUS.invoke("ap not available");
        apWarning=<APWarningDiv>Your browser doesn’t support Apple Pay on the web, use Safari on MacOS or Google Pay.</APWarningDiv>
    }

    

    if(payinfo.psys==="gp" || payinfo.psys==="apgp" || !payinfo.isApAvailable)
        buttons.push(<GPButton payinfo={payinfo} key={1} />)

    if(payinfo.isApAvailable && (payinfo.psys==="ap" || payinfo.psys==="apgp"))
        buttons.push(<APButton payinfo={payinfo} key={2} />)
    

    return <ContentDiv>

            <Info {...payinfo} />
            {apWarning}
            {buttons.map(val=>val)}

    </ContentDiv>
}