import styled from "styled-components"
import { S_RETRY_PAY } from "../GlobalObserver"

const StatusDiv = styled.div`
    border:4px green solid;
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    text-transform: capitalize;
    font-size: 15pt;
    background-color: #FFFFFF;
    &[data-status="error"]{
        border-color: red;
    }
    &[data-status="failed"]{
        border-color: red;
    }
    &[data-status="card"]{
        border-color: orange;
    }
    &[data-status="processing"]{
        border-color: #0059ff;
    }
    &>div{
        padding: 0 5pt;
        font-size:12pt;
        text-align: center;
        opacity: .6;
    }
    gap:5pt;
`

const WrongCardDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap:5px;
    align-items: flex-start;
    &>div:first-child{
        font-size: 1.3em;
        color:#e91e63;
    }
    &>div:nth-child(2){
        font-size:1em;
        margin-bottom: 20px;
    }
`
const Button = styled.div`
    background-color: rgba(0,0,0,.8);
    border-radius: 4px;
    padding:10px 20px;
    color:white;
    cursor: pointer;
    transition: background-color .2s;
    :hover{
        background-color: rgba(0,0,0,1);
    }
`
export const Status = ({status,message}:{status:string,message?:string|null})=>{
    if(status==="card"){
        return <WrongCardDiv>
            <div>WRONG CARD TYPE</div>
            <div>ONLY <b>MASTERCARD</b> ACCEPTED</div>
            <Button onClick={()=>S_RETRY_PAY.invoke()}>OK</Button>
        </WrongCardDiv>
    }
    return <StatusDiv data-status={status.toLowerCase()}>
        {status}{message && <div>{message}</div>}
    </StatusDiv>
}