import styled from "styled-components"
import { GlobalStyle } from "../css/GlobalStyle"
import { Logo } from "./Logo"
import { AppRouter } from "./AppRouter"





const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
`

export const App = ({error}:{error?:boolean})=>{

    return <>
        <GlobalStyle/>
        <MainDiv>
            <Logo/>
            <AppRouter />
        </MainDiv>
    </>
}
